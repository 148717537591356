import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { Box, InputBase, Typography } from '@mui/material';
import {
  BeatLoader,
  CountryFlag as UICountryFlag,
  toTitleCase,
} from '@wr/web-ui';
import * as React from 'react';

import {
  ENTER_KEY_INPUT,
  SPACE_CODE,
  SPACE_CODE_OLD,
} from '@/components/exchange-calculator/core/core.constants';
import { IOS } from '@/constants';
import { AppContext } from '@/context';
import { colors } from '@/styles';

import { Autocomplete } from '../autocomplete';
import { CountryRow } from '../country-row';
import { Dropdown } from '../dropdown';
import { useStyles } from './country-field.styles';
import { PricingCalculatorInputProps } from './country-field.types';
import {
  filterCountries,
  removeScroll,
  sortArrayByProperty,
} from './country-field.utils';

export const CountryField: React.FC<PricingCalculatorInputProps> = ({
  inputId,
  loading,
  selectedCountry,
  inputLabel,
  placeholder,
  countries,
  inputValue,
  onChange,
  handleCountryChange,
}) => {
  const classes = useStyles();
  const rootEl = React.useRef<HTMLDivElement>(null);
  const searchCountryInputEl = React.useRef<HTMLElement | null>(null);
  const [searchText, setSearchText] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);
  const { osName } = React.useContext(AppContext);

  const isIOS = osName === IOS;
  const open = Boolean(anchorEl);

  const toggleDropDown = () => {
    if (loading) {
      return;
    }

    if (isIOS) {
      removeScroll();
    }

    if (anchorEl) {
      setSearchText('');
      setAnchorEl(null);
      return;
    }

    setAnchorEl(rootEl?.current);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
  ): void | boolean => {
    if (e.key === ENTER_KEY_INPUT) {
      (e.target as HTMLElement).blur();
    }
  };

  const handleCountrySelectKeyDown = (event: React.KeyboardEvent): void => {
    if (!open && (event.key === SPACE_CODE || event.key === SPACE_CODE_OLD)) {
      event.preventDefault();
      toggleDropDown();
    }
  };

  return (
    <div className={classes.inputContainer} ref={rootEl}>
      <Box className={classes.inputRoot}>
        <Typography className={classes.inputTitle}>{inputLabel}</Typography>
        {loading ? (
          <BeatLoader size={6} className={classes.loader} />
        ) : (
          <InputBase
            data-testid={inputId}
            inputProps={{
              'className': classes.inputBaseProps,
              'aria-label': `${inputId}-pricing-calculator-input-label`,
              'inputMode': 'numeric',
              'type': 'decimal',
              'pattern': '[0-9]*',
              'autoComplete': 'off',
            }}
            disabled={false}
            className={classes.pricingCalculatorRoot}
            placeholder={placeholder}
            onChange={onChange}
            onKeyDown={handleKeyDown}
            value={inputValue}
            inputRef={searchCountryInputEl}
          />
        )}
      </Box>
      <Box
        onKeyDown={handleCountrySelectKeyDown}
        className={classes.selectContainer}
      >
        <Box
          className={classes.currency}
          onClick={toggleDropDown}
          data-testid={`${inputId}-country-select`}
        >
          {selectedCountry?.iso2 && (
            <UICountryFlag
              className={classes.flag}
              code={selectedCountry?.iso2?.toUpperCase()}
            />
          )}
          <Typography
            variant="h6"
            component="strong"
            color={colors.sendwaveBrown}
            paddingLeft={0.8}
          >
            {selectedCountry?.currency?.toUpperCase() || ''}
          </Typography>
          <ExpandMoreRoundedIcon
            fontSize="large"
            htmlColor={
              countries.length > 1
                ? colors.sendwaveBrown
                : colors.sendwaveYellow
            }
          />
        </Box>
        <Dropdown
          id={`send-field-container-search-input-container`}
          isOpen={open}
          anchorEl={anchorEl}
          onClose={toggleDropDown}
          showCloseIcon={false}
          width={rootEl?.current?.offsetWidth}
        >
          <Box style={{ background: 'white' }}>
            <CloseIcon className={classes.icon} onClick={toggleDropDown} />
            <Typography variant="h5" className={classes.title}>
              Select a country and currency
            </Typography>
            <Autocomplete
              name="send-field-container"
              placeholder="Search"
              searchText={searchText}
              setSearchText={setSearchText}
              options={sortArrayByProperty(countries, 'name')}
              noOptionsText={'No countries found'}
              inputRef={searchCountryInputEl}
              getOptionLabel={option => `${option.iso2}-${option.currency}`}
              filterOptions={options => filterCountries(options, searchText)}
              renderOption={(props, option) => (
                <li {...props} style={{ borderTop: '1px solid #E6EAEE' }}>
                  <CountryRow
                    countryCode={option.iso2.toLowerCase()}
                    countryName={toTitleCase(option.name.toUpperCase() || '')}
                    currencyCode={option?.currency?.toUpperCase() || ''}
                    currencyName={option.name}
                  />
                </li>
              )}
              onChange={(_event, newValue) => {
                if (newValue) {
                  handleCountryChange(newValue);
                  toggleDropDown();
                }
              }}
              isIOS={isIOS}
            />
          </Box>
        </Dropdown>
      </Box>
    </div>
  );
};
