import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { TextSectionGridProps } from './text-section-grid.types';

export const useStyles = makeStyles<
  Theme,
  Partial<TextSectionGridProps<unknown>>
>(theme => ({
  textSection: ({ backgroundColor }) => ({
    ...(backgroundColor ? { backgroundColor } : {}),
    padding: theme.spacing(4, 0),

    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(4, 0),
    },
  }),
  gridContainer: ({
    imagePosition,
    imagePositionMobile,
    columnAlignment,
    contentAlign,
  }) => {
    let alignItems;
    switch (columnAlignment) {
      case 'top':
        alignItems = 'start';
        break;
      case 'bottom':
        alignItems = 'end';
        break;
      default:
        alignItems = 'center';
    }

    const flexDirection =
      imagePositionMobile === 'bottom' ? 'column-reverse' : 'column';
    const flexDirectionSm = imagePosition === 'right' ? 'row-reverse' : 'row';

    return {
      display: 'flex',
      justifyContent: 'center',
      textAlign: contentAlign || 'left',
      alignItems,
      flexDirection,
      [theme.breakpoints.up('sm')]: {
        flexDirection: flexDirectionSm,
      },
    };
  },
  gridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  gridImageItem: ({ imageAlignment }) => {
    let justifyContent = 'center';
    switch (imageAlignment) {
      case 'left':
        justifyContent = 'flex-start';
        break;
      case 'right':
        justifyContent = 'flex-end';
        break;
      default:
        justifyContent = 'center';
    }
    return {
      justifyContent,
    };
  },
  image: ({ hasMobileImage, imageMaxWidth, mobileImageMaxWidth }) => ({
    'width': '100%',
    'height': 'auto',

    '&.image-desktop': {
      'max-width': imageMaxWidth ? `${imageMaxWidth}px` : '100%',
    },

    '&.image-mobile': {
      'max-width': mobileImageMaxWidth ? `${mobileImageMaxWidth}px` : '100%',
      'display': 'none',
    },

    [theme.breakpoints.down('sm')]: {
      '&.image-desktop': {
        display: hasMobileImage ? 'none' : 'block',
      },
      '&.image-mobile': {
        display: hasMobileImage ? 'block' : 'none',
      },
    },
  }),
  contentContainer: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      '& .MuiTypography-root': {
        fontSize: '0.8rem',
      },
      '& .MuiTypography-h2': {
        fontSize: '1.4rem',
      },
      '& .MuiTableCell-root': {
        padding: '10px',
      },
    },
  },
}));
