import { Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeBreakpoints } from '@wr/web-ui';
import { Maybe } from 'graphql/jsutils/Maybe';

import { colors } from '@/styles';

import { HeroTextVariationProps } from './hero-text-variation.types';

type HeroTextVariationStyles = Pick<HeroTextVariationProps, 'textColor'> & {
  backgroundColor?: Maybe<string>;
  backgroundImage?: Maybe<string>;
};

export const useStyles = makeStyles<Theme, HeroTextVariationStyles>(
  ({ breakpoints, spacing }) => ({
    heroTextSection: ({ backgroundColor, backgroundImage }) => ({
      width: '100%',
      position: 'relative',
      backgroundColor: backgroundColor || colors.sendwaveYellow,
      backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100vw auto',
      overflow: 'hidden',

      [breakpoints.down(ThemeBreakpoints.tablet)]: {
        backgroundPosition: 'left bottom',
        backgroundSize: 'cover',
      },
    }),
    heroTextSectionContainer: {
      display: 'flex',
      paddingTop: spacing(6),
      paddingBottom: spacing(6),
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',

      [breakpoints.up(ThemeBreakpoints.tablet)]: {
        paddingTop: spacing(15),
        paddingBottom: spacing(15),
      },
    },
    footerImage: {
      width: '100%',
      height: '50px',
      position: 'absolute',
      top: 'unset',
      bottom: '0',
      left: '0',
      right: '0',

      [breakpoints.up(ThemeBreakpoints.mobile)]: {
        height: '90px',
      },

      [breakpoints.up(ThemeBreakpoints.tablet)]: {
        height: '130px',
      },

      [breakpoints.up(ThemeBreakpoints.desktop)]: {
        height: '170px',
      },
    },
    heroTextContent: {
      width: '100%',
    },
    heroTextHeading: {
      color: props => props.textColor || colors.sendwaveBlack,
      fontSize: '2rem',
      lineHeight: '1.3',
      textAlign: 'center',
      [breakpoints.up(ThemeBreakpoints.mobile)]: {
        fontSize: '2.4rem',
      },

      [breakpoints.up(ThemeBreakpoints.tablet)]: {
        fontSize: '3.6rem',
      },
    },
    heroTextDescription: {
      color: props => props.textColor || colors.sendwaveBlack,
      fontWeight: 700,
      textAlign: 'center',
      marginTop: spacing(1.5),
      fontSize: spacing(2.7),

      [breakpoints.down(ThemeBreakpoints.mobile)]: {
        fontSize: spacing(2.5),
      },
    },
    heroWrapper: {
      display: 'flex',

      [breakpoints.down(ThemeBreakpoints.tablet)]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
  }),
);
